<template>
  <div>
    <div class="row">
      <!-- <div class="col-md-4 d-block ">
        <b-form-group
          label="List Showing :"
          label-for="name-input"
          invalid-feedback="Company is required"
          :state="nameState"
          direction="vertical"
          v-if="$route.name === 'uploaded-roster'"
        >
          <v-select
            v-model="query.list_showing"
            :options="listShowing"
            label="name"
            class="bg-light"
            placeholder="Select customer"
            :clearable="false"
            direction="vertical"
            :single-line="true"
            @input="getFilterItems"
          />
        </b-form-group>
      </div> -->
      <div
        class="col-md-4"
        :class="
          logedUser.roles[0].name === 'customer' ||
          logedUser.roles[0].name === 'employee'
            ? 'd-none'
            : ''
        "
      >
        <b-form-group
            label="Subsidiary Company :"
            label-for="name-input"
            invalid-feedback="Company is required"
            :state="nameState"
            direction="vertical"
          >
            <v-select
              v-model="query.company"
              :options="companies"
              label="name"
              class="bg-light"
              placeholder="Select customer"
              :clearable="false"
              direction="vertical"
              :single-line="true"
              @input="getCustomers"
              :disabled="logedUser.roles[0].name === 'admin' ? true : false"
            />
          </b-form-group>
        </div>
      <!-- </div>
      <div class="row"> -->
        <div
          class="col-md-4 "
          :class="logedUser.roles[0].name === 'employee' ? 'd-none' : 'd-block'"
          v-if="logedUser.roles[0].name !== 'customer'"
        >
          <b-form-group
              label="Client :"
          label-for="name-input"
          invalid-feedback="Customer is required"
          :state="nameState"
          direction="horizontal"
        >
          <v-select
            v-model="query.customer"
            direction="horizontal"
            :options="customers"
            label="name"
            class="bg-light"
            placeholder="Select Customer"
            :clearable="false"
            @input="getFilterItems"
            :disabled="logedUser.roles[0].name === 'customer' ? true : false"
          />
        </b-form-group>
      </div>
      <!-- <div class="col-md-4" :class="logedUser.roles[0].name === 'employee' ? 'd-none' : 'd-block'">
        <b-form-group
          label="Expertise :"
          label-for="name-input"
          invalid-feedback="Expertise is required"
          :state="nameState"
        >
          <v-select
            v-model="query.sector"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="sectors"
            label="name"
            class="bg-light"
            placeholder="Select Expertise"
            :clearable="true"
            direction="vertical"
            @input="getFilterItems"
          />
        </b-form-group>
      </div> -->
      <div
        class="col-md-4"
        :class="
          logedUser.roles[0].name === 'customer' ||
          logedUser.roles[0].name === 'employee'
            ? 'd-none'
            : ''
        "
      >
        <b-form-group
          label="View As :"
          label-for="name-input"
          invalid-feedback="Expertize is required"
          :state="nameState"
        >
          <v-select
            v-model="query.view_as"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="viewAs"
            label="name"
            class="bg-light"
            placeholder="Select Expertize"
            :clearable="false"
            direction="vertical"
            @input="getFilterItems"
          />
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import { getLogedInUser } from "@/utils/auth";
import { BCard, BFormGroup, BFormInput, BFormTimepicker } from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  data() {
    return {
      query: {
        role: "admin",
        company: null,
        customer: null,
        sector: null,
        user: null,
        user_id: "",
        customer_id: "",
        sector_id: "",
        view_as: { id: 1, name: "Employee List" },
        list_showing: { id: 0, name: "Pending List" },
        status: 0,
        view_id: 1,
        employee_id: "",
      },
      nameState: null,
      companies: [],
      customers: [],
      sectors: [],
      viewAs: [
        { id: 1, name: "Employee List" },
        { id: 2, name: "Client List" },
      ],
      listShowing: [
        { id: 0, name: "Pending List" },
        { id: 1, name: "Accepted List" },
      ],
      logedUser: JSON.parse(getLogedInUser()),
    };
  },
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BFormTimepicker,
    vSelect,
  },
  mounted() {
    this.getCompanies();
    this.getSectors();
  },
  methods: {
    getCompanies() {
      this.$store
        .dispatch("roster/rosterFilter", this.query)
        .then(({ data }) => {
          this.companies = data;
          if (this.companies.length > 0) {
            if (this.logedUser.roles[0].name === "superadmin") {
              this.query.company = this.companies[0];
            } else {
              if (this.logedUser.roles[0].name === "admin") {
                this.query.company = this.companies.find(
                  (company) => company.id === this.logedUser.id
                );
              } else {
                this.query.company = null;
              }
            }
            this.getCustomers();
          }
        });
    },
    getSectors() {
      this.$store.dispatch("sector/getSectors").then((response) => {
        this.sectors = response.data.data;
      });
    },
    async getCustomers() {
      if (this.query.company && this.query.company.id) {
        this.query.user_id = this.query.company.id;
        this.query.user = this.query.company;
        await this.$store
          .dispatch("roster/rosterFilter", this.query)
          .then((response) => {
            this.customers = response;
            if (this.customers.length > 0) {
              this.query.customer = this.customers[0];
            } else {
              this.query.customer = null;
            }
            
          });
      } else {
         this.query.user_id = "";
        if (this.logedUser.roles[0].name === "customer") {
          this.query.customer = this.logedUser;
        }
      }
      // this.getFilterItems();
    },
    getFilterItems() {
      if (this.logedUser.roles[0].name === "superadmin") {
        this.query.user_id = this.query.user
          ? this.query.user.id
          : this.companies[0]?.id;
      } else if (this.logedUser.roles[0].name === "admin") {
        this.query.user_id = this.query.user
          ? this.query.user.id
          : this.logedUser.id;
      }

      this.query.customer_id = this.query.customer
        ? this.query.customer.id
        : this.customers[0]?.id ? this.customers[0]?.id : "";
      if(this.logedUser.roles[0].name === "employee"){
        this.query.employee_id = this.logedUser.id;
      }
      if (this.logedUser.roles[0].name === "customer") {
          this.query.e_status = 2;
        }
      this.query.sector_id = this.query.sector ? this.query.sector.id : "";
      this.query.view_id = this.query.view_as.id;
      this.$emit("filter", this.query);
    },
  },
  watch: {
    query: {
      handler: function (newVal, oldVal) {
        this.getFilterItems();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
