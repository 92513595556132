<template>
  <div id="sister-roster">
    <b-card>
      <FullCalendar ref="FullCalendar" :options="calendarOptions">
        <template v-slot:eventContent="arg">
          <b>{{ arg.timeText }}</b>
          <i>{{ arg.event.title }}</i>
        </template>
      </FullCalendar>
    </b-card>
  </div>
</template>
<script>
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import FullCalendar from "@fullcalendar/vue";
import { BCard, BFormGroup, BFormInput, BFormTimepicker } from "bootstrap-vue";
import vSelect from "vue-select";
import { getToken } from "@/utils/auth";
import store from "@/store";
import { VUE_APP_BASE_URL } from "@/utils/var";
export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BFormTimepicker,
    FullCalendar, // make the <FullCalendar> tag available
    vSelect,
  },
  props: {
    filterData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      duties: [],
      customers: [],
      shifts: [],
      roster: {
        duty: null,
        shift: null,
        customer: null,
        customer_id: "",
        user_id: "",
        duty_id: "",
        start_time: "",
        end_time: "",
        date: "",
        employee_id: "",
        shift_id: "",
      },
      nameState: null,
      calendarOptions: {
        plugins: [resourceTimelinePlugin, interactionPlugin, dayGridPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "resourceTimelineTenDay resourceTimelineFourtenDay resourceTimelineTwentyOneDay resourceTimelineTwentyEightDay",
        },
        initialView: "resourceTimelineTenDay",
        aspectRatio: 1.5,
        selectable: true,
        selectMirror: true,
        navLinks: true, // can click day/week names to navigate views
        editable: true,
        eventLimit: true,
        resourceAreaColumns: [
          {
            field: "title",
            headerContent: "Company List",
          },
        ],
        eventTimeFormat: {
          date: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        },
        views: {
          resourceTimelineTenDay: {
            type: "resourceTimeline",
            duration: { week: 1 },
            dateIncrement: { weeks: 1 },
            buttonText: "1 Week",
            slotDuration: { days: 1 },
          },
          resourceTimelineFourtenDay: {
            type: "resourceTimeline",
            duration: { week: 2 },
            dateIncrement: { weeks: 2 },
            buttonText: "2 Week",
            slotDuration: { days: 1 },
          },
          resourceTimelineTwentyOneDay: {
            type: "resourceTimeline",
            duration: { week: 3 },
            dateIncrement: { weeks: 3 },
            buttonText: "3 Week",
            slotDuration: { days: 1 },
          },
          resourceTimelineTwentyEightDay: {
            type: "resourceTimeline",
            duration: { week: 4 },
            dateIncrement: { weeks: 4 },
            buttonText: "4 Week",
            slotDuration: { days: 1 },
          },
        },
        slotLabelFormat: [
          { weekday: "short", year: "numeric", month: "short", day: "numeric" },
        ],
        slotLabelInterval: { days: 1 },
        eventClick(info) {
          // let self = this.el.__vue__;
          // self.$bvModal.show("modal-prevent-closing");
          // assign event to modal
        },
        dateClick(info) {
          // get vue instance
          // let self = this.el.__vue__;
          // self.$bvModal.show("modal-prevent-closing");
          // self.$parent.roster.date = info.dateStr;
          // self.$parent.roster.employee_id = info.resource.id;
        },
        //update event
        eventDrop: function (info) {
          alert(
            `Event Drop <> Employee ID: ${info?.event?.id}-${info?.event?.title} <> Date: ${info?.event?.startStr}`
          );
        },
        events: {
          url: `${VUE_APP_BASE_URL}/roster/event`,
          method: "GET",
          extraParams: {
            type: "event",
            Authorization: `Bearer ${getToken()}`,
            // status: 0,
            user_id : 0,
            // customer_id : 0,
            // sector: 0,
            'role': 'customer',
          },
        },
        resources: {
          url: `${VUE_APP_BASE_URL}/roster/resource`,
          method: "GET",
          extraParams: {
            Authorization: `Bearer ${getToken()}`,
            'role': 'customer',
            user_id : 0,
            // customer_id : 0,
            // sector: 0,
          },
        },
        eventDidMount: function (info) {
        //   let duty = info.event.extendedProps.duty.name;
          info.el.style.margin = "5px";

          let div1 = document.createElement("div");
          let div2 = document.createElement("div");
          div1.classList.add("duty");
          div2.classList.add("time");
          div1.innerHTML = info.event.extendedProps.duty ? info.event.extendedProps.duty.name : 'N/A';
        //   div2.innerHTML = info.event.extendedProps.name ? info.event.extendedProps.name.name : 'N/A';
          // console.log(info.event.extendedProps)
          let img = document.createElement("img");
          img.style.height = "50px";
          img.style.width = "50px";
          img.style.borderRadius = "50%";
          img.style.border = "1px solid #000";
          img.style.objectFit = "cover";
          img.style.objectPosition = "center";
          img.setAttribute("v-b-tooltip.hover", '');
          img.setAttribute("title", info.event.extendedProps.name);
          img.classList.add("event-img");
          img.src = info.event.extendedProps.img.includes('https://ui-avatars.com') ? info.event.extendedProps.img : store.state.appConfig.imageBaseUrl + info.event.extendedProps.img;
          info.el.style.marginTop = "5px";
          info.el.append(div1, div2, img);
        //   info.resource.extendedProps.img.includes('https://ui-avatars.com') ? info.resource.extendedProps.img : store.state.appConfig.imageBaseUrl + info.resource.extendedProps.img
          // {description: "Lecture", department: "BioChemistry"}
        },
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        resourceAreaWidth: "15%",
        resourceLabelDidMount: function (info) {
          let find = info.el.querySelector(".fc-datagrid-cell-cushion");
          //find.classList.add('labelLane');
          let id = document.createElement("span");
          id.classList.add("fc-datagrid-cell-main");
          id.classList.add("id");
          let div = document.createElement("span");
          let industry = document.createElement("span");
          let sister = document.createElement("span");
          industry.classList.add("fc-datagrid-cell-main");
          sister.classList.add("fc-datagrid-cell-main");
          industry.classList.add("name");
          sister.classList.add("name");
          let img = document.createElement("img");
          img.style.height = "50px";
          img.style.width = "50px";
          img.style.borderRadius = "50%";
          img.style.border = "1px solid #000";
          id.innerHTML = 'ID'+info.resource.id;
          industry.innerHTML = info.resource.extendedProps.industry ? info.resource.extendedProps.industry : 'N/A';
          sister.innerHTML = info.resource.extendedProps.company ? info.resource.extendedProps.company : 'N/A';
          img.src = info.resource.extendedProps.img.includes('https://ui-avatars.com') ? info.resource.extendedProps.img : store.state.appConfig.imageBaseUrl + info.resource.extendedProps.img;
          div.innerHTML = find.prepend(img, id, industry, sister);
          // div.innerHTML = find.append(industry);
          // return { domNodes: [div] };
        },
      },
    };
  },

  methods: {
    reCall(){
      this.calendarOptions.events.extraParams.user_id = this.filterData.user_id;
      this.calendarOptions.resources.extraParams.user_id = this.filterData.user_id;
      // this.calendarOptions.events.extraParams.customer_id = this.filterData.customer_id;
      // this.calendarOptions.resources.extraParams.customer_id = this.filterData.customer_id;
      this.$refs.FullCalendar.getApi().refetchEvents();
      this.$refs.FullCalendar.getApi().refetchResources();
    }
  },
  // watch: {
  //   filterData: {
  //     handler: function (val) {
  //       console.log(val);
  //       // refresh calendar events on filter change with new extra params
  //       this.calendarOptions.events.extraParams.user_id = val.user_id;
  //       this.calendarOptions.events.extraParams.customer_id = val.customer_id;
  //       this.$refs.FullCalendar.getApi().refetchEvents();
  //       this.$refs.FullCalendar.getApi().refetchResources();
  //     },
  //     deep: true,
  //   },
  // }
};
</script>
<style scoped>
.fc-license-message {
  display: none;
}
.timeline-event {
  background-color: #f00;
  color: #000;
  border: 1px solid #f00;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}
.fc-datagrid-cell-frame:last-child {
  border: 1px solid #bebebe;
}
.fc-timeline-lane-frame {
  border: 1px solid #bebebe;
}

.labelLane {
  align-items: center !important;
  border-radius: 0;
  display: flex !important;
  /*font-size: var(--fc-small-font-size);*/
  margin-bottom: 1px !important;
  padding: 2px 1px !important;
  position: relative !important;
  font-weight: bold;
}

.timeline-event {
  display: flex;
  align-items: center;
}
.fc-timeline-event .fc-event-main i {
  color: #fff !important;
  display: none
}
.id {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #264296;
}


</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
