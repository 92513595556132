/**
 * Validate an email address
 * @param {String} email
 * @return {Boolean}
 */
import moment from "moment";

export function validEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
// check if an object key is empty
export function isEmpty(obj) {
  let empty;
  for (let key in obj) {
    empty = obj[key] === "" || obj[key] === null ? true : false;
    // if empty is true, break the loop
    if (empty) {
      break;
    }
  }
  return empty;
}

export function getMyEvent(obj) {
  console.log("obj");
}

export function timeValidatorBetween(roster_time, employee_time) {
  // 1 min add from roster start time and 1 min subtract from roster end time
  let start = moment(roster_time.date + " " + roster_time.start_time)
    .add(1, "minutes")
    .format("HH:mm A");
  let end = moment(roster_time.date + " " + roster_time.end_time)
    .subtract(2, "minutes")
    .format("HH:mm A");
  let employeeStart = moment(
    roster_time.date + " " + employee_time.start_time
  ).format("HH:mm A");
  let employeeEnd = moment(roster_time.date + " " + employee_time.end_time)
    .subtract(1, "minutes")
    .format("HH:mm A");

  if(
    moment(start, "HH:mm A").isBetween(
      moment(employeeStart, "HH:mm A"),
      moment(employeeEnd, "HH:mm A")
    ) &&
    moment(end, "HH:mm A").isBetween(
      moment(employeeStart, "HH:mm A"),
      moment(employeeEnd, "HH:mm A")
    )
  ) {
    return true;
  } else {
    return false;
  }
}

export function validateTimes(new_roster, existing_rosters){
  let hasError = false;
  existing_rosters.forEach((event) => {
    let start = new Date(event.start_time);
    let end = new Date(event.end_time);
    let start_time = new Date(new_roster.date + " " + new_roster.start_time);
    let end_time = new Date(new_roster.date + " " + new_roster.end_time);
    // roster date block is already in selected date block
    if (start_time <= start && end_time >= end && !hasError) {
      hasError = true;
    } else if (start_time >= start && start_time <= end && !hasError) {
      hasError = true;
    } else if (end_time >= start && end_time <= end && !hasError) {
      hasError = true;
    }
  });
  return hasError;
}

// export function checkCustomValidity(arr){
//   let array = arr;
//   array.forEach((element) => {
//     if (
//       element.name === "" ||
//       element.name === null ||
//       element.name === undefined
//     ) {
//       element.isValid = false;
//     } else {
//       element.isValid = true;
//     }
//   });
//   return array;
//   // check any state is false
// }

export function previourRosterTimeValidator(data){
  let hours = 0;
  if(data.previous_work_end){
    // get the largest time between two times
    let largestTime = moment.max([
      moment(data.previous_work_end),
      moment(data.previous_roster_end),
    ]);
    // get time defarence between largest time and roster start time
    hours = moment(data.roster_start_time).diff(largestTime, "hours");
  } else {
    // get hours defarence between previous work end time and roster start time
    hours = moment(data.roster_start_time).diff(
      moment(data.previous_roster_end),
      "hours"
    );
  }
  if(hours <= 10){
    return false;
  } else {
    return true;
  }
}