<template>
  <div id="roster">
    <b-card>
      <filter-component class="m-2" @filter="filter" />
      
      <customer-roster-component ref="customer-roster" :filterData="filterData" v-if="filterData.view_as && filterData.view_as.name === 'Employee List'" />
      <sister-company-roster-component ref="sister-company-roster" :filterData="filterData" v-else />
    </b-card>
  </div>
</template>
<script>
import { BCard, BFormGroup, BFormInput, BFormTimepicker } from "bootstrap-vue";
import vSelect from "vue-select";
import CustomerRosterComponent from './components/CustomerRosterComponent.vue';
import FilterComponent from "./components/FilterComponent.vue";
import SisterCompanyRosterComponent from './components/SisterCompanyRosterComponent.vue';
export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BFormTimepicker,
    vSelect,
    CustomerRosterComponent,
    SisterCompanyRosterComponent,
    FilterComponent
  },
  data() {
    return {
      options: ['Customer View', 'Sister Company View'],
      view: 'Customer View',
      filterData: {
        role: "admin",
        company: null,
        customer: null,
        sector: null,
        user_id: null,
        customer_id: null,
        sector_id: null,
        status: 0,
      },
    };
  },
  methods: {
    filter(query) {
      this.filterData = query;
      // call reCall function of child component
      if(this.filterData.view_as && this.filterData.view_as.name === 'Employee List'){
        this.$refs['customer-roster']?.reCall();
      } else {
        this.$refs['sister-company-roster']?.reCall();
      }
    },
  },
};
</script>
<style scoped>
.title {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 43px;
  color: #515B60;
  margin-left: 20px;
}
.row {
  margin-right: 10px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
